import { Resource } from '../src/data/resource'

const description = [
  'We’ve documented how hundreds of high-performance design teams operate.',
  'Patterns show that successful teams all invest in six key areas of development,',
  'but that design leaders are typically in the dark about which areas most deserve their attention.',
].join(' ')
const shared = { description }

const list: Resource[] = [
  {
    ...shared,
    id: 'level-up-a-tool-for',
    title: 'Level Up. A Tool for Growing High-Performing Design Teams',
    url: '/resources/level-up-a-tool-for',
    category: {
      id: '1234',
      name: 'Design Leadership',
    },
    image: {
      src: 'https://source.unsplash.com/random/?grow',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'designer-founders-book',
    title: 'Design Founders Book',
    url: '/resources/designer-founders-book',
    category: {
      id: '1234',
      name: 'Founders',
    },
    image: {
      src: 'https://source.unsplash.com/random/?book',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'designers-guide-to-working-in-tech',
    title: "A Designer's Guide to Working in Tech",
    url: '/resources/designers-guide-to-working-in-tech',
    category: {
      id: '1234',
      name: 'Career Guidance',
    },
    image: {
      src: 'https://source.unsplash.com/random/?designer',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'right-way-accept-job-offer',
    title: 'The Right Way to Accept a Job Offer',
    url: '/resources/right-way-accept-job-offer',
    category: {
      id: '1234',
      name: 'Career Guidance',
    },
    image: {
      src: 'https://source.unsplash.com/random/?office',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'design-critiques-how-to-maintain',
    title: 'Design Critiques: How to Maintain Trust at Scale',
    url: '/resources/design-critiques-how-to-maintain',
    category: {
      id: '1234',
      name: 'Design Leadership & Management',
    },
    image: {
      src: 'https://source.unsplash.com/random/?nature',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'shaping-design-culture',
    title: 'Shaping Design Culture',
    url: '/resources/shaping-design-culture',
    category: {
      id: '1234',
      name: 'Culture',
    },
    image: {
      src: 'https://source.unsplash.com/random/?culture',
      alt: 'FPO from Unsplash',
    },
  },
  {
    ...shared,
    id: 'dos-and-donts-design-portfolio',
    title: '5 Dos and Donts for Your Design Portfolio',
    url: '/resources/dos-and-donts-design-portfolio',
    category: {
      id: '1234',
      name: 'Career Guidance',
    },
    image: {
      src: 'https://source.unsplash.com/user/gk3',
      alt: 'FPO from Unsplash',
    },
  },
]

export default list
