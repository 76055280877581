import * as React from 'react'

import { SEO } from '../../components/seo'
import { CompanyList, ResourceList } from '../../components/list'

import MOCK_COMPANIES from '../../../mock/companies'
import MOCK_RESOURCES from '../../../mock/resources'

type Props = {}

export default class StyleguideListsPage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row">
            <h1 className="type-h1">Lists</h1>
          </div>
        </header>
        <section className="container container--grid">
          <div className="container__row">
            <h2 className="type-h2">CompanyList</h2>
            <div style={{ maxWidth: 300 }}>
              <CompanyList>
                {MOCK_COMPANIES.slice(0, 7).map(c => (
                  <CompanyList.Item
                    key={c.slug}
                    name={c.name}
                    category={c.category}
                    slug={c.slug}
                  />
                ))}
              </CompanyList>
            </div>
          </div>
        </section>
        <section className="container container--grid">
          <div className="container__row">
            <h2 className="type-h2">ResourceList</h2>
            <div style={{ maxWidth: 560 }}>
              <ResourceList.Header
                heading="Design Manage &amp; Leadership"
                subheading="Category 01"
                description="We’ve documented how hundreds of high-performance design teams operate. Patterns show that successful."
              />
              <ResourceList>
                {MOCK_RESOURCES.slice(0, 7).map(c => (
                  <ResourceList.Item
                    key={c.slug}
                    title={c.title}
                    slug={c.slug}
                  />
                ))}
              </ResourceList>
            </div>
          </div>
        </section>
      </>
    )
  }
}
