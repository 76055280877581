import { Company } from '../src/data/types'
// homepage slider image
import imageHeader from '../src/images/company/stripe/header.jpg'
// homepage slider logo
import imageLogo from '../src/images/company/stripe/logo.png'

const header = { src: imageHeader, alt: 'FPO header image' }
const logo = { src: imageLogo, alt: 'FPO logo image' }
const description = [
  'Etiam gravida id augue ac rutrum.',
  'Maecenas pretium quam magna, sed sollicitudin ipsum facilisis sit amet.',
].join(' ')
const shared = { header, logo, description, featured: false }

const list: Company[] = [
  {
    ...shared,
    name: 'Abstract',
    category: 'Design tools',
    slug: '/company/abstract',
  },
  {
    ...shared,
    name: 'AltSchool',
    category: 'Education',
    slug: '/company/alt-school',
  },
  {
    ...shared,
    name: 'AndCo',
    category: 'Financial services',
    slug: '/company/and-co',
  },
  {
    ...shared,
    name: 'Better',
    category: 'Healthcare',
    slug: '/company/better',
  },
  {
    ...shared,
    name: 'Delighted',
    category: 'Education',
    slug: '/company/delighted',
  },
  {
    ...shared,
    name: 'Framer',
    category: 'Design tools',
    slug: '/company/framer',
  },
  {
    ...shared,
    name: 'MixMax',
    category: 'Tech',
    slug: '/company/mix-max',
  },
  {
    ...shared,
    name: 'Elevate',
    category: 'Education',
    slug: '/company/elevate',
  },
  {
    ...shared,
    name: 'Noun Project',
    category: 'Design tools',
    slug: '/company/noun-project',
  },
  {
    ...shared,
    name: 'Gusto',
    category: 'Financial services',
    slug: '/company/gusto',
  },
  {
    ...shared,
    name: 'Heartbeat',
    category: 'Healthcare',
    slug: '/company/heartbeat',
  },
  {
    ...shared,
    name: 'Moxxly',
    category: 'Healthcare',
    slug: '/company/moxxly',
  },
  {
    ...shared,
    name: 'Hustle',
    category: 'Tech',
    slug: '/company/hustle',
  },
  {
    ...shared,
    name: 'Netlify',
    category: 'Tech',
    slug: '/company/netlify',
  },
  {
    ...shared,
    name: 'Omada',
    category: 'Healthcare',
    slug: '/company/omada',
  },
  {
    ...shared,
    name: 'Podia',
    category: 'Education',
    slug: '/company/podia',
  },
  {
    ...shared,
    name: 'Vibes',
    category: 'Tech',
    slug: '/company/vibes',
  },
  {
    ...shared,
    name: 'Operator',
    category: 'Financial services',
    slug: '/company/operator',
  },
  {
    ...shared,
    name: 'Remix',
    category: 'Transportation',
    slug: '/company/remix',
  },
  {
    ...shared,
    name: 'Stripe',
    category: 'Financial services',
    slug: '/company/stripe',
  },
  {
    ...shared,
    name: 'Wake',
    category: 'Design tools',
    slug: '/company/wake',
  },
]

export default list
